import React from "react"

const Roadmap = ({data, className}) => {
	const roadmapData = data.sort((a,b) => {
		if (a.year === b.year) {
			return a.quarter > b.quarter ? 1 : -1;
		}
		return a.year > b.year ? 1 : -1;
	}).slice(-3);


	let isSpotlightSet = false;
	roadmapData.forEach((x) => {
		if(x.spotlight_quarter){
			isSpotlightSet = true
		}
		if(isSpotlightSet){
			x.past = false
		}
		else{
			x.past = true
		}
	})
	let quarterSections = roadmapData.map((node) => {
		let key = `${node.quarter}${node.year}`
		let timelineDotClass = (node.past) ? 'roadmap-timeline-dot past' : 'roadmap-timeline-dot';
		timelineDotClass = (node.spotlight_quarter) ? 'roadmap-timeline-dot spotlight' : timelineDotClass;

		return (
			<div className="roadmap-quarter-section" key={key}>
				<div className="roadmap-quarter-year">
					<p className="roadmap-text-quarter">{node.quarter}</p>
					<p className="roadmap-text-year">{node.year}</p>
				</div>
				<div className={timelineDotClass}></div>
				<ul>
					{
						node.milestones.map((milestone, index) => {
							let key = `${node.quarter}${node.year}${index}`
							let listItemClass = (milestone.milestone_completed) ? 'roadmap-milestone-complete' : ''
							return <li className={listItemClass} key={key}>{milestone.milestone_line_item}</li>
						})
					}
				</ul>
			</div>
		)
	})

	return(<>

		<div className={`roadmap mt-5 ${className}`}>
			{quarterSections}
			<div className="roadmap-image-desktop">
				<span className="roadmap-timeline-line"></span>
			</div>
			
		</div>

	</>)
}

export default Roadmap

