import React, { useState }from "react"
import { UncontrolledCollapse, Collapse, Button } from 'reactstrap';

const ReleaseNotes = ({data}) => {

	let fields = data.map((node, index) => {
		let fields = node.node.fields

		let columns = fields.elements

		let versionNum = fields.version
		let versionId = `toggler-${versionNum.replace(/\./g,'-')}`
		let fixes = createColumn(columns.fixes, "fixes", versionId, 4)
		let improvements = createColumn(columns.improvements, "improvements", versionId, 4)
		let details = createColumn(columns.detailed_release_notes, "details", versionId, 9)
		
		return(
			<div key={versionNum} className="token-text">
				<AccordianCard 
					index = {index}
					title = {`Version ${versionNum}`}
					id = {versionId}>
					<div className="row">
			    		<div className="col-md-5">
			    			{ 
								columns.fixes.length > 0 &&
				    			<div>
				    				<p className="mb-0 font-weight-bold">Fixes:</p>
				    				{fixes}
				    			</div>
			    			}
			    			{ 
								columns.improvements.length > 0 &&
				    			<div>
			    				<p className="mb-0 font-weight-bold">Improvements:</p>
				    				{improvements}
				    			</div>
							}
			    		</div>
			    		<div className="col-md-7">
							{ 
								columns.detailed_release_notes.length > 0 &&
				    			<div>
			    				<p className="mb-0 font-weight-bold">Detailed release notes:</p>
				    				{details}
				    			</div>
							}			    			
			    		</div>
			    	</div>
				</AccordianCard>
			    
			</div>
		)
	})

	return(<>

		<div className="px-md-5">
			{fields}
		</div>

	</>)
}

export default ReleaseNotes

function createColumn(column, name, versionId, threshold){

	let visible = []
	let hidden = []

	if(column === undefined) return

	column.forEach((x, index) => {
		let item = <li key={`${versionId}-fix-${index}`} dangerouslySetInnerHTML={{__html:x}}></li>
		if(index < threshold){
			visible.push(item)
		}
		else{
			hidden.push(item)
		}
	})

	if(hidden.length !== 0){
		let id = `${versionId}-${name}-read-more`
		return(
			<>
				<ul className="accordian-card-list mb-0">
					{visible}
				</ul>
				<UncontrolledCollapse toggler={`#${id}`} className="accordian-read-more-hidden">
					<ul className="accordian-card-list mb-0">
						{hidden}
					</ul>
				</UncontrolledCollapse>
				<Button id={id} className="accordian-read-more">read</Button>
			</>
		)
	}
	else{
		
		return (
			<ul className="accordian-card-list">
			{ visible }
			</ul>
		)
	}

}

const AccordianCard = (props) => {
 	let { title, id, index } = props

 	let initState = (index === 0) ? true : false;

	const [collapse, setCollapse] = useState(initState);

	const toggle = () => setCollapse(!collapse);

	const show = (collapse) ? "show" : ""
 	
 	return(

 		<>
	 	<Button 
			id={id} 
			className={`${show} w-100 bg-white text-dark text-left font-weight-bold border-0 accordian-button position-relative py-3`}
			onClick={toggle}
			>
			<div className="accordian-button-title" dangerouslySetInnerHTML={{__html:title}}>
			</div>
		</Button>
		<Collapse isOpen={collapse} className={`px-4 accordian-card text-left`}>
	    	{props.children}
	    </Collapse>
		</>
	)

}