import React, { useState, useContext } from "react"
import { graphql } from "gatsby"
import useTranslations from "../../utils/useTranslations"
import { CompatibilityContext } from "../../components/compatibilityContext";
import Logo from "../../components/logo"
import Button2 from "../../components/button"
import ReleaseNotes from "../../components/releaseNotes"
import Roadmap from "../../components/roadmap"

import heroImgWebp from "../../images/token/bittorrent-speed/speed-hero.webp"
import heroImg from "../../images/token/bittorrent-speed/speed-hero.png"
import heroImgWebp2x from "../../images/token/bittorrent-speed/speed-hero@2x.webp"
import heroImg2x from "../../images/token/bittorrent-speed/speed-hero@2x.png"

import step1ImgWebp from "../../images/token/bittorrent-speed/step-1.webp"
import step1Img from "../../images/token/bittorrent-speed/step-1.png"
import step1ImgWebp2x from "../../images/token/bittorrent-speed/step-1@2x.webp"
import step1Img2x from "../../images/token/bittorrent-speed/step-1@2x.png"

import step2ImgWebp from "../../images/token/bittorrent-speed/step-2.webp"
import step2Img from "../../images/token/bittorrent-speed/step-2.png"
import step2ImgWebp2x from "../../images/token/bittorrent-speed/step-2@2x.webp"
import step2Img2x from "../../images/token/bittorrent-speed/step-2@2x.png"

import step3ImgWebp from "../../images/token/bittorrent-speed/step-3.webp"
import step3Img from "../../images/token/bittorrent-speed/step-3.png"
import step3ImgWebp2x from "../../images/token/bittorrent-speed/step-3@2x.webp"
import step3Img2x from "../../images/token/bittorrent-speed/step-3@2x.png"

import dashboardImgWebp from "../../images/token/bittorrent-speed/dashboard.webp"
import dashboardImg from "../../images/token/bittorrent-speed/dashboard.png"
import dashboardImgWebp2x from "../../images/token/bittorrent-speed/dashboard@2x.webp"
import dashboardImg2x from "../../images/token/bittorrent-speed/dashboard@2x.png"

import walletImgWebp from "../../images/token/bittorrent-speed/wallet.webp"
import walletImg from "../../images/token/bittorrent-speed/wallet.png"
import walletImgWebp2x from "../../images/token/bittorrent-speed/wallet@2x.webp"
import walletImg2x from "../../images/token/bittorrent-speed/wallet@2x.png"

import ChimpForm from "../../components/chimpForm"
import LocalizedLink from "../../components/localizedLink"
import roadmapImg from "../../images/token/bittorrent-speed/speed-roadmap.svg"
import roadmapMobileImg from "../../images/token/bittorrent-speed/roadmap-mobile.svg"
import backgrounds from "../../components/backgrounds"
import smileImg from "../../images/components/featuresList/smiley.svg"
import backupImg from "../../images/components/featuresList/backup.svg"
import nopeImg from "../../images/components/featuresList/nope.svg"
import circleCheckImg from "../../images/components/featuresList/circleCheck.svg"


const heroImgAlt = "BitTorrent Token Logo"
const btWebLink = "/downloads/complete"

const TokenBittorentSpeed = ({ pageContext: {text}, data }) =>  {

    const t = useTranslations(text)

    const compatibilityHandler = useContext(CompatibilityContext)

    function handleClick(e){

      compatibilityHandler(e, 'downloading', "Web", ["Win", "Osx"], btWebLink)
    }

    return (
      <>
        {/* Start - hero */}
        <div className="text-center text-md-left top-section-container p-0 py-5 background-noise-dark" style={backgrounds.darkBg}>

          <div className="container">

            <div className="row mt-md-5 text-white">

              <div className="col-md-6 py-md-3 order-md-1 px-md-0">
                <Logo color="white-speed" tag="h1" productName="Speed" className="mb-4"/>
                <img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4"></img>
                <p className="text-size-24 text-spacing-1 font-weight-normal">
                  {t(`Get faster downloads and BTT token rewards.`)}
                </p>
                <ul className="live-list mt-2 align-self-center align-self-md-left text-left">
                	<li>
                		{t(`Powered by BTT token and the blockchain.`)}
                	</li>
                	<li>
                		{t(`Drives faster torrent download speeds.`)}
                	</li>
                	<li>
                		{t(`Earn BTT crypto torrent token.`)}
                	</li>
                </ul>
                <div>
	                <Button2 onClick={(e) => {handleClick(e)}} href={btWebLink} id="token-bittorrent-speed-hero-cta" className=" btn-primary text-spacing-1 mt-3 mt-md-3 mb-4 button-filled text-spacing-1">{t(`Free Download`)}</Button2>
                  <div className="d-md-inline-block">
                    <LocalizedLink to="/token/bittorrent-speed/faq" id="token-bittorrent-speed-hero-faq"  className="feature-list-learn-more ml-md-3">
                      <span className="text-white text-decoration-none font-weight-normal">
                        Read the FAQ
                      </span>
                    </LocalizedLink>
                    <div className="ml-1 feature-list-arrow-black d-inline-block align-middle">{`>`}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-5 order-md-2 text-center">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-inline"></img>*/}
                
                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} className="img-fluid d-none d-md-inline" alt={heroImgAlt}></img>
                </picture>


              </div>

            </div>

          </div>
          
        </div> 
        {/* End - Hero */}

        {/* Start - Sign up */}
        <div className="container-fluid py-5 token-mail-container">
          <div className="container px-0 text-center" >
            <p className="text-size-24">{t(`Sign up to receive BitTorrent Speed updates`)}</p>
            <div className="col-md-6 offset-md-3">
              <ChimpForm 
                  list="https://bittorrent.us5.list-manage.com/subscribe/post?u=a3baea4e54ff8e8b235488c11&id=ea7f201ccb"
                  classInput="token-mail-input"
                  classButton="token-mail-button"
                  buttonText={t(`SIGN UP`)}
                  placeholder="Enter your email"
                />
            </div>
            <p className="mb-0">{t(`By signing up, you agree to our <LocalizedLink to='/legal/privacy-policy' className="text-dark">Privacy Policy</LocalizedLink>.`)}</p>
          </div>
        </div>
        {/* End - Sign up */}

        <div className="container-fluid pb-md-5 ">
          <div className="container px-0 pb-5 text-center text-md-left">

            {/* Start - How it Works */}
            <div className="token-section-header">
              <p className="token-section-header-title">{t(`How it Works`)}</p>
              <p className="token-section-header-subtitle">{t(`3 steps to faster torrent downloads and BTT rewards.`)}</p>
            </div>

            <div className="row mb-5 justify-content-between">
              <div className="col-12 col-md-5 token-text py-4">
                <p className="token-text text-size-24 font-weight-bold">{t(`Step 1 - Enable your wallet`)}</p>
                <p className="token-text">{t(`BitTorrent Speed is a feature that extends the traditional experience of our Windows and Mac-based torrent clients. When downloading or upgrading to the latest version of µTorrent Classic for Windows, or BitTorrent or µTorrent Web, a digital cryptocurrency wallet and a BTT token balance will be automatically enabled.`)}</p>
              </div>
              <div className=" col-12 col-md-5 offset-md-1 align-items-center d-flex justify-content-center justify-content-md-start">
                {/*<img src={step1Img} alt="Enable your wallet" className="img-fluid d-md-inline shadow-lg"></img>*/}

                <picture>
                  <source srcSet={`${step1ImgWebp} 1x, ${step1ImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${step1Img} 1x, ${step1Img2x} 2x`} src={step1Img} className="img-fluid d-md-inline shadow-lg wallet-steps-img" alt="Enable your wallet"></img>
                </picture>

              </div>
            </div>

            <div className="row my-5 justify-content-between">
              <div className="col-12 col-md-5 token-text py-4 order-md-2 offset-md-1">
                <p className="token-text text-size-24 font-weight-bold">{t(`Step 2 - Enjoy faster download speeds`)}</p>
                <p className="token-text">{t(`When downloading torrents, BitTorrent Speed automatically bids BitTorrent (BTT) to other users for faster speeds. Just use the torrent program like normal, there is nothing else you need to learn or do.`)}</p>
              </div>
              <div className=" col-12 col-md-5 order-md-1 pl-md-0 align-items-center d-flex justify-content-center justify-content-md-start">
                {/*<img src={step2Img} alt="Enjoy faster download speeds" className="img-fluid d-md-inline shadow-lg"></img>*/}

                <picture>
                  <source srcSet={`${step2ImgWebp} 1x, ${step2ImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${step2Img} 1x, ${step2Img2x} 2x`} src={step2Img} className="img-fluid d-md-inline shadow-lg wallet-steps-img" alt="Enjoy faster download speeds"></img>
                </picture>

              </div>
            </div>

            <div className="row my-5 justify-content-between">
              <div className="col-12 col-md-5 token-text py-4">
                <p className="token-text text-size-24 font-weight-bold">{t(`Step 3 - Earn BTT`)}</p>
                <p className="token-text">{t(`When a torrent file finishes downloading, your BitTorrent client can automatically earn BTT by seeding. The longer you seed torrents, the more BTT crypto torrent tokens you may be able to earn.`)}</p>
              </div>
              <div className=" col-12 col-md-5 offset-md-1 align-items-center d-flex justify-content-center justify-content-md-start">
                {/*<img src={step3Img} alt="Earn BTT" className="img-fluid d-md-inline shadow-lg"></img>*/}

                <picture>
                  <source srcSet={`${step3ImgWebp} 1x, ${step3ImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${step3Img} 1x, ${step3Img2x} 2x`} src={step3Img} className="img-fluid d-md-inline shadow-lg wallet-steps-img" alt="Earn BTT"></img>
                </picture>

              </div>
            </div>
            {/* End - How it Works */}

          </div>
        </div>

        {/* Start - Features */}
        <div className="container-fluid pb-5 py-md-5 speed-section-gray">
          <div className="container px-0 pt-5 text-center text-md-left">
          	<div className="pb-md-5">
              <p className="token-section-header-title">{t(`Features`)}</p>
              <p className="token-section-header-subtitle">{t(`Earnings dashboard and encrypted wallet, all in one.`)}</p>
            </div>

            <div className="row my-5 justify-content-between">
              <div className="col-12 col-md-5 token-text pt-5">
                <p className="token-text text-size-24 font-weight-bold">{t(`The Dashboard`)}</p>
                <p className="token-text">{t(`Track and manage your earnings and improved download speed in one place.`)}</p>
              </div>
              <div className=" col-12 col-md-5 offset-md-1 text-left d-flex justify-content-center justify-content-md-start">
                {/*<img src={dashboardImg} alt="The Dashboard" className="img-fluid d-md-inline shadow-lg"></img>*/}
                
                <picture>
                  <source srcSet={`${dashboardImgWebp} 1x, ${dashboardImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${dashboardImg} 1x, ${dashboardImg2x} 2x`} src={dashboardImg} className="img-fluid d-md-inline shadow-lg" alt="The Dashboard"></img>
                </picture>

              </div>
            </div>

            <div className="row my-5 justify-content-between">
              <div className="col-12 col-md-5 offset-md-1 token-text pt-5 order-md-2">
                <p className="token-text text-size-24 font-weight-bold">{t(`The Wallet`)}</p>
                <p className="token-text">{t(`Monitor your BTT assets in the encrypted Wallet. Send or receive tokens with a simple click.`)}</p>
              </div>
              <div className="col-12 col-md-5 text-right order-md-1 d-flex justify-content-center justify-content-md-start">
                {/*<img src={walletImg} alt="The Wallet" className="img-fluid d-md-inline shadow-lg"></img>*/}

                <picture>
                  <source srcSet={`${walletImgWebp} 1x, ${walletImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${walletImg} 1x, ${walletImg2x} 2x`} src={walletImg} className="img-fluid d-md-inline shadow-lg" alt="The Wallet"></img>
                </picture>

              </div>
            </div>
          </div>
        </div>
        {/* End - Features */}

        {/* Start - Benefits */}
        <div className="container-fluid py-md-5">
          <div className="container px-0 py-5" style={backgrounds.tokenBg}>
          	<div className="">
              <p className="token-section-header-title">{t(`Benefits`)}</p>
              <p className="token-section-header-subtitle">{t(`Reasons to try BitTorrent Speed`)}.</p>
            </div>

            <div className="row token-text text-center mt-md-5 ">
            	<div className="col-md-5 my-3 my-md-5">
                <img src={smileImg}/>
            		<p className="text-size-24 font-weight-bold mt-3 mb-4">{t(`Easy to use`)}</p>
            		<p>{t(`The benefits of BitTorrent Speed are automated, including token bidding for faster downloads and BTT token rewards. There is nothing new you need to learn.`)}</p>
            	</div>
            	<div className="offset-md-2 col-md-5 my-3 my-md-5">
                <img src={backupImg}/>
            		<p className="text-size-24 font-weight-bold mt-3 mb-4">{t(`Backward compatible`)}</p>
            		<p>{t(`Not interested in earning tokens with BitTorrent Speed? You can keep using your existing client, as it’s fully compatible with the new generation of BitTorrent Speed enabled torrent clients.`)}</p>
            	</div>
            	<div className="col-md-5 my-3 my-md-5">
                <img src={nopeImg}/>
            		<p className="text-size-24 font-weight-bold mt-3 mb-4">{t(`No mining`)}</p>
            		<p>{t(`The blockchain does not need your computer resources to process transactions, so BitTorrent applications will never mine.`)}</p>
            	</div>
            	<div className="offset-md-2 col-md-5 my-3 my-md-5">
                <img src={circleCheckImg}/>
            		<p className="text-size-24 font-weight-bold mt-3 mb-4">{t(`Free to use`)}</p>
            		<p>{t(`Despite the addition of BitTorrent Speed, the family of BitTorrent products will always be free for everyone to use.`)}</p>
            	</div>
            	
            </div>
          </div>
        </div>
        {/* End - Benefits */}

        {/* Start - Roadmap */}
        <div className="container-fluid py-md-5 speed-section-black background-noise-dark" style={backgrounds.darkBg}>
          <div className="container px-0 py-5 token-text text-center" >
          	<p className="token-section-header-title font-weight-bold text-white">
	        		{t(`Roadmap`)}
        	</p>
        	<p className="text-size-24">
        		{t(`Learn where our team is taking BitTorrent Speed`)}.
        	</p>
        	{/* <img src={roadmapImg} className="img-fluid my-5 px-5 d-none d-md-block" alt=""></img> */}
        	{/* <img src={roadmapMobileImg} className="img-fluid my-5 d-block d-md-none" alt=""></img> */}
          <Roadmap className = "test" data = {data.allWordpressAcfRoadmaps.nodes[0].acf.Roadmaps}/>
          </div>
        </div>
        {/* End - Roadmap */}

        {/* Start - Release Notes */}
        <div className="container-fluid py-md-5">
          <div className="container px-0 py-5 text-center">
          	<p className="token-section-header-title font-weight-bold">
	        		{t(`Product Release Notes`)}
        	</p>
        	<p className="text-size-24">
        		{t(`Monitor our progress in greater detail.`)}
        	</p>
          <ReleaseNotes data = {data.allWordpressWpCustomRelease.edges}/>
          </div>
        </div>
        {/* End - Release Notes */}

        

        {/* Start - Tips */}
        <div className="container-fluid py-md-5">
    			<div className="container px-0 pt-5">
    				<p className="word-cloud font-weight-bold word-cloud-speed">
    			    <span className="font-weight-bold text-size-50">
    			      Tips
    			    </span> <span className="font-weight-normal text-size-24">
    			      on how to
    			    </span> <span className="textsize-40">
    			      optimize
    			    </span> <span className="font-weight-normal text-size-24">
    			      your BitTorrent
    			    </span>  <span className="text-size-30">download</span> <span className="font-weight-normal text-size-50">
    			      speed
    			    </span> 
    			  </p>
    			</div>

  		    <div className="container">
  		    	<div className="row mb-5">
  		    		<div className="offset-md-1 col-md-2 text-md-right product-faq-question mb-3 mb-md-0">
  		    		</div>
  		    		<div className="offset-md-1 col-md-8 product-faq-answer">
  		    			{t(`<b>BitTorrent Speed is a product that enables faster download speeds</b> but it isn’t the only way to boost the performance of your torrent client. Below are a few tips to help speed up the BitTorrent Classic desktop torrent client and the BitTorrent Web online torrent downloader.`)} 
  		    		</div>
  		    	</div>
  		    	<div className="row py-5">
  		    		<div className="offset-md-1 col-md-2 text-md-right product-faq-question mb-3 mb-md-0">
  		    			{t(`<b>Check</b> the number of <b>seeds</b>`)}
  		    		</div>
  		    		<div className="offset-md-1 col-md-8 product-faq-answer">
  		    			{t(`Because the BitTorrent protocol relies on users with other BitTorrent clients to seed (also known as sharing) the file you wish to download, it’s important to check the number of seeds. If the torrent has a few or no seeds, it may not be possible to download all of the files you need. Similarly, if the ratio of seeders (users sharing your file) is significantly outnumbered by the number of leechers (users downloading your file), then it may take longer for your torrent program to download the complete torrent. Inside BitTorrent Classic, you can check this ratio by clicking the ‘Trackers’ tab and looking in the Seeds and Peers column.`)} 

  		    		</div>
  		    	</div>
  		    	<div className="row py-5">
  		    		<div className="offset-md-1 col-md-2 text-md-right product-faq-question mb-3 mb-md-0">
  		    			{t(`<b>Limit</b> the number of <b>downloads</b>`)}
  		    		</div>
  		    		<div className="offset-md-1 col-md-8 product-faq-answer">
  		    			{t(`Although BitTorrent Classic is a bulk torrent downloader, and BitTorrent Web enables you to download multiple files simultaneously, the more downloads you have running, the longer each individual download will take. If you need to optimize your BitTorrent download speed for a single torrent, keep that in mind before you begin several downloads. Or, if the torrents are already downloading, pause a few to speed up your priority downloads.`)}
  		    		</div>
  		    	</div>
	        </div>

        </div>
        {/* End - Tips */}


      </>
    )

}

export default TokenBittorentSpeed

export const pageQuery = graphql`
query querySpeedReleaseNotes {
  allWordpressWpCustomRelease(filter: {release_cat: {eq: 1801}}) {
    edges {
      node {
        content
        date
        title
        fields {
          version
          elements {
            detailed_release_notes
            fixes
            improvements
          }
        }
      }
    }
  }
  allWordpressAcfRoadmaps(filter : {wordpress_id: {eq: 8877}}) {
    nodes {
      wordpress_id
      acf {
        Roadmaps {
          quarter
          year
          spotlight_quarter
          milestones {
            milestone_line_item
            milestone_completed
          }
        }
      }
    }
  }
}

`